import React, {useState, useEffect, FC} from "react";
import {Dropdown, Icon, Modal, Toggle} from "@stats/playbook-components";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {CompetitionMenuItem, SelectorMenu} from "./SelectorMenu";
import _ from "lodash";
import {ClientProductTournamentCalendar} from "../ClientPage/types";
import {AccessTokenOptions, addClientProductTournamentCalendar,} from "../apiHelpers";
import {useClientProductTournamentCalendarProvider} from "../../providers/ClientProductTournamentCalendarProvider";
import {trackPromise} from "react-promise-tracker";
import {useAuth0} from "@auth0/auth0-react";

type AvailableSport = 'soccer' | 'basketball' | 'cricket' | 'tennis';

const PAGE_SIZE = 400

const SPORT_LEAGUE_URLS = {
  soccer: {
    url: "https://api.performfeeds.com/soccerdata/tournamentcalendar/1unark1ekfm741dr67t4zoifgw/active/authorized?_rt=c&_fmt=json",
    paginate: true,
  },
  basketball: {
    url: "https://api.performfeeds.com/basketballdata/tournamentcalendar/1unark1ekfm741dr67t4zoifgw/active/authorized?_rt=c&_fmt=json",
    paginate: false,
  },
  cricket: {
    url: "https://api.performfeeds.com/cricketdata/tournamentcalendar/1unark1ekfm741dr67t4zoifgw/authorized?_rt=c&_fmt=json",
    paginate: false,
  },
}

// Tennis not included in the SPORT_LEAGUE_URLS object because it has a different structure
// The /authorized endpoint returns 403 -> for now using the "all" endpoint
// url: "https://api.performfeeds.com/tennisdata/tourcalendar/1unark1ekfm741dr67t4zoifgw/authorized?_rt=c&_fmt=json"
const TENNIS_CALENDAR_URL = "https://api.performfeeds.com/tennisdata/tourcalendar/1unark1ekfm741dr67t4zoifgw?_rt=c&_fmt=json"

function createLeagueName (league: SDAPILeague): string {
  if (!league.country || league.name.includes(league.country)) return league.name;
  return `${league.country} ${league.name}`
}

async function fetchCompetitions(sport: keyof typeof SPORT_LEAGUE_URLS): Promise<SDAPILeague[]> {
  if (SPORT_LEAGUE_URLS[sport].paginate) {
    let pageNumber = 1    
    const leagues: SDAPILeague[] = []

    while (true) {
      try {
        const url = `${SPORT_LEAGUE_URLS[sport].url}&_pgSz=${PAGE_SIZE}&_pgNm=${pageNumber}`
        const response = await trackPromise(fetch(url))
        if (!response.ok) {
          alert(`Failed to fetch competitions for ${sport} on page ${pageNumber}`)
        }
        const json = await response.json() as SDAPITeamsResponse

        leagues.push(...json.competition)
        if (json.competition.length < PAGE_SIZE) {
          break
        }

        pageNumber++
      } catch (e) {
        break
      }
    }
    return leagues
  }

  const response = await trackPromise(fetch(SPORT_LEAGUE_URLS[sport].url))
  if (!response.ok) {
    alert(`Failed to fetch competitions for ${sport}`)
    return []
  }
  const json = await response.json() as SDAPITeamsResponse
  return json.competition
}

async function getSportLeagues(sport: keyof typeof SPORT_LEAGUE_URLS): Promise<CompetitionMenuItem[]>{
  const competitions = await fetchCompetitions(sport as keyof typeof SPORT_LEAGUE_URLS)

  return competitions.map((competition) => {
    return {
      competitionId: competition.id,
      tournamentId: competition.tournamentCalendar[0].id,
      value: `${sport}_${competition.id}`,
      displayValue: createLeagueName(competition),
      sport,
    }
  }).sort((a, b) => a.displayValue.localeCompare(b.displayValue))
}

async function getTennisTours(): Promise<CompetitionMenuItem[]> {
  const response = await trackPromise(fetch(TENNIS_CALENDAR_URL))
  if (!response.ok) {
    alert(`Failed to fetch competitions for tennis`)
    return []
  }
  const json = await response.json() as TennisResponse

  const tours: CompetitionMenuItem[] = json.tour.map((tour) => ({
    competitionId: tour.id, // we use only tour ID for tennis
    tournamentId: tour.id, // we use only tour ID for tennis
    value: `tennis_${tour.id}`,
    displayValue: tour.tourName,
    sport: 'tennis',
  }))

  return tours.sort((a, b) => a.displayValue.localeCompare(b.displayValue))
}

const StyledModalLauncher = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-transform: uppercase;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  .row {
    margin-bottom: 16px;
  }

  .selectors {
    display: flex;
  }

  .arrows {
    flex: 0 0 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

type TournamentCalendar = {
  id: string;
};

type SDAPILeague = {
  id: string;
  name: string;
  country: string;
  tournamentCalendar: TournamentCalendar[];
};

type SDAPITeamsResponse = {
  competition: SDAPILeague[];
};

type TennisResponse = {
  tour: Array<{
    id: string;
    tourName: string;
    tourCalendar: Array<{
      id: string;
      tourYear: string;
      startDate: string;
      endDate: string;
      active: 'Yes'|'No';
      lastUpdated: string;
    }>;
  }>;
}

type EditLeaguesModalProps = {
  clientHash: string;
  productId: string;
};

function getLeagueIdsPermissionedForClientInDynamo(
  tournamentCalendars: ClientProductTournamentCalendar[] = []
): string[] {
  return tournamentCalendars.map((league) => league.leagueId) || [];
}

const EditLeaguesModal: FC<EditLeaguesModalProps> = ({
                                                             clientHash,
                                                             productId,
                                                           }) => {
  const {tournamentCalendars, updateTournamentCalendars} =
    useClientProductTournamentCalendarProvider();
  const [open, setOpen] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [available, setAvailable] = useState<CompetitionMenuItem[]>([]);
  const [selectedSport, setSelectedSport] = useState<AvailableSport>('soccer');
  const [selectedAvailable, setSelectedAvailable] = useState<
    CompetitionMenuItem[]
  >([]);
  const [permissioned, setPermissioned] = useState<CompetitionMenuItem[]>(
    []
  );
  const [totalLeagues, setTotalLeagues] = useState<Record<string, CompetitionMenuItem[]>>({})
  const [selectedPermissioned, setSelectedPermissioned] = useState<
    CompetitionMenuItem[]
  >([]);
  const {getAccessTokenSilently} = useAuth0();
  
  const availableOnItemSelected = (value: string): void => {
    if (selectedAvailable.map(x => x.value).includes(value)) {
      setSelectedAvailable(selectedAvailable.filter((s) => s.value !== value));
    } else {
      const newItem = available.find((a) => a.value === value);
      newItem && setSelectedAvailable([...selectedAvailable, newItem]);
    }
  };
  
  const permissionedOnItemSelected = (value: string): void => {
    if (selectedPermissioned.filter((s) => s.value === value).length === 1) {
      setSelectedPermissioned(
        selectedPermissioned.filter((s) => s.value !== value)
      );
    } else {
      const newItem = permissioned.find((a) => a.value === value);
      newItem && setSelectedPermissioned([...selectedPermissioned, newItem]);
    }
  };
  
  const availableOnClick = (): void => {
    setSaveEnabled(true);
    setPermissioned([...permissioned, ...selectedAvailable]);
    setSelectedAvailable([]);
    setAvailable(_.difference(available, selectedAvailable));
  };
  
  const permissionedOnClick = (): void => {
    setSaveEnabled(true);
    setAvailable([...available, ...selectedPermissioned]);
    setSelectedPermissioned([]);
    setPermissioned(_.difference(permissioned, selectedPermissioned));
  };
    
  useEffect(() => {
    let isMounted = true;
    
    async function getAvailableAndPermissionedLeagues(): Promise<void> {
      const aggregatedLeagues = {
        'soccer': await getSportLeagues('soccer'),
        'basketball': await getSportLeagues('basketball'),
        'cricket': await getSportLeagues('cricket'),
        'tennis': await getTennisTours(),
      }
      
      setTotalLeagues(aggregatedLeagues)
      
      const allLeaguesForSport = aggregatedLeagues[selectedSport] || []
      
      if (isMounted) {
        const clientDynamoLeagues = getLeagueIdsPermissionedForClientInDynamo(tournamentCalendars) || [];
        
        setPermissioned(
          allLeaguesForSport
            .filter((league) => clientDynamoLeagues?.includes(league.competitionId))
            .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
        );
        setAvailable(
          allLeaguesForSport
            .filter((league) => !clientDynamoLeagues.includes(league.competitionId))
            .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
        );
      }
    }
    
    getAvailableAndPermissionedLeagues()
      .then();
    return (): void => {
      isMounted = false;
    };
  }, [tournamentCalendars]);
  
  useEffect(() => {
    const allLeaguesForSport = totalLeagues[selectedSport] || []
    const clientDynamoLeagues = getLeagueIdsPermissionedForClientInDynamo(tournamentCalendars) || [];
    
    const permissionedLeagues = allLeaguesForSport
      .filter((league) => clientDynamoLeagues.includes(league.competitionId))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    const availableLeagues = allLeaguesForSport
      .filter((league) => !clientDynamoLeagues.includes(league.competitionId))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    setPermissioned(permissionedLeagues);
    setAvailable(availableLeagues);
    setSelectedPermissioned([])
    setSelectedAvailable([])
  }, [selectedSport])
  
  function convertToClientProductTournamentCalendars(
    leaguesToUpdate: CompetitionMenuItem[]
  ): ClientProductTournamentCalendar[] {
    return leaguesToUpdate.map((league) => {
      return {
        clientHash: clientHash,
        productId: productId,
        tournamentCalendarId: league.tournamentId,
        leagueId: league.competitionId,
        leagueName: league.displayValue,
        sport: selectedSport,
      };
    });
  }
  
  function saveClientProductTournamentCalendars(organizationId: string, productId: string) {
    return (): void => {
      const clientDynamoLeagues =
        getLeagueIdsPermissionedForClientInDynamo(tournamentCalendars);
      const leaguesToAdd = permissioned.filter((league) =>
        !clientDynamoLeagues.includes(league.competitionId)
      );
      const leaguesToRemove = available.filter((league) =>
        clientDynamoLeagues.includes(league.competitionId)
      );
      getAccessTokenSilently(AccessTokenOptions)
        .then(accessToken => {
          addClientProductTournamentCalendar(
            convertToClientProductTournamentCalendars(leaguesToAdd),
            convertToClientProductTournamentCalendars(leaguesToRemove),
            organizationId,
            productId,
            accessToken
          ).then(() => {
            updateTournamentCalendars();
            setOpen(false);
            setSaveEnabled(false);
          });
        })
    };
  }
  
  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <Modal
            title={"EDIT Leagues & Competitions"}
            style={{height: "604px", width: "616px"}}
            handleClose={(): void => setOpen(false)}
            modalAction={{
              text: "Save",
              onClick: saveClientProductTournamentCalendars(clientHash, productId),
              disabled: !saveEnabled,
            }}
          >
            <StyledWrapper>
              <div className={"row"}>
                <Dropdown
                  label={"Sport"}
                  menuItems={[
                    {name: "Football", value: "soccer"},
                    {name: "Basketball", value: "basketball"},
                    {name: "Cricket", value: "cricket"},
                    {name: "Tennis", value: "tennis"},
                  ]}
                  value={selectedSport}
                  onItemSelect={(newSport: string): void => {
                    setSelectedSport(newSport as AvailableSport)
                  }}
                />
              </div>
              <div className={"row selectors"}>
                <SelectorMenu
                  menuId={"available"}
                  label={"Leagues/Competitions Available"}
                  menuItems={available}
                  selectedMenuItems={selectedAvailable}
                  onItemSelected={availableOnItemSelected}
                />
                <div className={"arrows"}>
                  <Icon
                    variant={"chevron-right"}
                    fill={"sp-mid-gray"}
                    onClick={availableOnClick}
                  />
                  <Icon
                    variant={"chevron-left"}
                    fill={"sp-mid-gray"}
                    onClick={permissionedOnClick}
                  />
                </div>
                <SelectorMenu
                  menuId={"permissioned"}
                  label={"Leagues/Competitions Permissioned"}
                  menuItems={permissioned}
                  selectedMenuItems={selectedPermissioned}
                  onItemSelected={permissionedOnItemSelected}
                />
              </div>
            </StyledWrapper>
          </Modal>,
          document.body
        )}
      <StyledModalLauncher onClick={(): void => setOpen(!open)}>
        {"Edit Leagues & competitions"}
      </StyledModalLauncher>
    </>
  );
};

export default EditLeaguesModal;
