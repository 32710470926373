import React from 'react';
import styled from "styled-components";
import {Button, palette} from "@stats/playbook-components";

const StyledSecondRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  
  .last-updated-wrapper {
      flex: 1 1;
  }
  
  .title {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .last-updated-text {
    font-size: 10px;
    color: ${palette["sp-dark-gray-25"]};
    font-style: italic;
  }
  
  .actions > button {
      margin-left: 16px;
   }
`;

type TileContentsHeaderAndButtonsProps = {
  title: string;
  lastUpdated: string;
  onClickSave?: () => void;
  onClickCancel?: () => void;
  enabledSaveButton: boolean;
}


const SecondaryHeaderAndButtons: React.FC<TileContentsHeaderAndButtonsProps> = ({
                                                                                  title,
                                                                                  lastUpdated,
                                                                                  onClickSave,
                                                                                  onClickCancel,
                                                                                  enabledSaveButton
                                                                                }) => {
  
  return <StyledSecondRow>
    <div className={"last-updated-wrapper"}>
      <div className={"title"}>{title}</div>
      <div className={"last-updated-text"}>Last updated at: {lastUpdated}</div>
    </div>
    <span className={"actions"}>
      {onClickSave && <Button variant={"primary"} onClick={onClickSave} disabled={!enabledSaveButton}>Save</Button>}
      {onClickCancel && <Button variant={"secondary-light"} onClick={onClickCancel}>Cancel</Button>}
    </span>
  </StyledSecondRow>;
};

export default SecondaryHeaderAndButtons;