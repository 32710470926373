import React from 'react';
import styled from 'styled-components';
import {palette} from "@stats/playbook-components";
import { Role } from "../../ProductPage/ProductPage.types";

const StyledFeatures = styled.div`
  .included-features {
    width: 100%;
    height: 160px;
    border: solid 1px ${palette["sp-light-gray-50"]};
    background: ${palette["sp-light-gray-25"]};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
  }

  .features-title {
    font-size: 10px;
    line-height: 1.6;
  }

  .read-only-feature {
    padding: 8px;
    color: ${palette["sp-dark-gray-25"]}
  }
`;

type IncludedFeaturesProps = {
  packageRoles: Role[];
  selectedPackage?: string;
};

const IncludedFeatures: React.FC<IncludedFeaturesProps> = ({
                                                             selectedPackage,
                                                             packageRoles
                                                           }) => {
  if (!selectedPackage || packageRoles.length === 0) {
    return null
  }
  
  const currentPackage = packageRoles.find(x => x.id === selectedPackage)
  
  return (
    <StyledFeatures>
      <div className='features-title'>
        {'Features included (Read-only)'}
      </div>
      <div className='included-features'>
        {
          currentPackage?.permissions.map(x => {
            return <div key={x.name} className='read-only-feature'>
              {x.name}
            </div>
          })
        }
      </div>
    </StyledFeatures>
  )
}

export default IncludedFeatures;